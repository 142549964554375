export const brandColors = {
  colors: {
    brand: {
      50: "#efe5ff",
      100: "#cfb3ff",
      200: "#b080ff",
      300: "#904dff",
      400: "#701aff",
      500: "#5700e6",
      600: "#4400b3",
      700: "#300080",
      800: "#1d004d",
      900: "#0a001a",
    },
  },
};
