import { inputAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { MultiSelectTheme } from "chakra-multiselect";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const outline = definePartsStyle({
  field: {
    borderRadius: "0.375rem",
  },
});
export const inputStyles = {
  components: {
    Input: defineMultiStyleConfig({
      variants: { outline },
    }),
    MultiSelect: {
      ...MultiSelectTheme,
    },
  },
};
