export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        // borderRadius: "15px",
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
