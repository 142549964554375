export const calendarStyles = {
    components: {
        CalendarDay: {
            baseStyle: {
                rounded: 'full',
                bgColor: 'transparent',
                color: 'black',
                _hover: {
                    bgColor: 'gray.100'
                },
                _disabled: {
                    color: 'gray.200',
                    _hover: {
                        cursor: 'initial',
                        bgColor: 'transparent'
                    }
                }
            },
            sizes: {
                sm: {
                    h: 8,
                    w: 8,
                    minW: 8,
                    m: 1
                }
            },
            variants: {
                selected: {
                    bgColor: 'brand.400',
                    color: 'white',
                    _hover: {
                        bgColor: 'brand.300'
                    }
                },
                range: {
                    bgColor: 'brand.200',
                    color: 'white',
                    _hover: {
                        bgColor: 'brand.100'
                    },
                    _disabled: {
                        _hover: {
                            bgColor: 'brand.300'
                        }
                    }
                },
                outside: {
                    color: 'gray.300'
                },
                today: {
                    bgColor: 'brand.100',
                    _hover: {
                        bgColor: 'brand.200'
                    }
                }
            },
            defaultProps: {
                size: 'sm'
            }
        }
    }
};
