import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { CalendarDefaultTheme } from "@uselessdev/datepicker";
import { brandColors } from "./brand";
import { breakpoints } from "./breakpoints";
import { buttonStyles } from "./components/button";
import { calendarStyles } from "./components/calendar";
import { inputStyles } from "./components/input";
import { globalStyles } from "./styles";

export const theme = extendTheme(
  { breakpoints, initialColorMode: "dark", useSystemColorMode: true },
  globalStyles,
  brandColors,
  buttonStyles,
  inputStyles,
  CalendarDefaultTheme,
  calendarStyles,
  withDefaultColorScheme({ colorScheme: "brand" })
);

export * from "./logos";
