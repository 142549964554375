import { createIcon } from "@chakra-ui/react";

export const logos = {
  wide: createIcon({
    displayName: "LogoWide",
    viewBox: "0 0 1817 230",
    defaultProps: {
      color: "inherit",
    },
    path: (
      <g fill="currentColor">
        <path d="M207 0H145.667V86.8888H61.3333V0H0V230H61.3333V143.111H145.667V230H207V0Z" />
        <path d="M452.333 0H383.333L339.889 74.111L296.444 0H227.444L309.222 132.889V230H370.555V132.889L452.333 0Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M549.444 0C574.522 0 596.31 6.11269 615.5 15.926C634.909 25.7393 650.065 39.2599 660.969 56.4878C671.872 73.4975 677.222 93.2332 677.222 115.695C677.222 137.938 671.872 157.674 660.969 174.902C650.065 192.13 634.909 205.65 615.5 215.464C596.31 225.277 574.522 230 549.444 230H472.777V0H549.444ZM550.369 173.778C559.964 173.778 568.796 172.794 576.865 169.959C584.933 167.124 591.912 163.199 597.8 158.183C603.688 153.168 608.267 147.171 611.538 140.192C614.81 133.214 615.888 123.722 615.888 115.217C615.888 106.712 614.81 97.2151 611.538 90.2367C608.267 83.2584 603.688 77.2613 597.8 72.2456C591.912 67.2299 584.933 63.2995 576.865 60.4645C568.796 57.6296 559.964 56.2223 550.369 56.2223H534.111V173.778H550.369Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M700.222 0.00764615H794.777C812.005 -0.210417 827.68 4.10833 840.983 10.8684C854.285 17.6284 864.644 26.8962 872.058 38.6718C879.691 50.4474 884.222 63.9628 884.222 79.2274C884.222 94.4921 879.691 108.126 872.058 120.12C864.911 131.47 855.029 140.492 842.411 147.182L891.888 230H822.888L781.999 158.447H761.555V230H700.222V0.00764615ZM794.472 102.228C799.924 102.228 804.722 101.15 808.865 99.1869C813.009 97.2243 816.28 94.4983 818.679 91.0092C821.077 87.52 822.888 83.5897 822.888 79.2283C822.888 72.686 819.66 67.3481 814.426 63.2047C809.41 58.8433 802.759 56.2283 794.472 56.2283H761.555V102.228H794.472Z"
        />
        <path d="M1023.98 230C1000.64 230 979.964 225.122 961.935 215.366C944.118 205.399 930.119 191.826 919.938 174.647C909.757 157.257 904.666 137.427 904.666 115.159C904.666 92.6786 909.757 72.8492 919.938 55.6708C930.119 38.2803 944.118 24.7072 961.935 14.9516C979.752 4.98386 1000.22 0 1023.34 0C1046.67 0 1067.25 4.98386 1085.06 14.9516C1102.88 24.7072 1116.88 38.2803 1127.06 55.6708C1137.24 72.8492 1142.33 92.6786 1142.33 115.159C1142.33 137.427 1137.24 157.257 1127.06 174.647C1117.09 191.826 1103.2 205.399 1085.38 215.366C1067.56 225.122 1047.1 230 1023.98 230ZM1023.98 175.92C1035.43 175.92 1045.51 173.375 1054.2 168.285C1062.9 162.983 1069.69 155.772 1074.56 146.653C1079.44 137.533 1081.88 127.035 1081.88 115.159C1081.88 103.283 1079.34 92.7846 1074.25 83.6652C1069.37 74.3337 1062.47 67.123 1053.57 62.0331C1044.87 56.7312 1034.79 54.0802 1023.34 54.0802C1012.1 54.0802 1002.02 56.7312 993.115 62.0331C984.418 67.123 977.525 74.3337 972.434 83.6652C967.556 92.7846 965.117 103.283 965.117 115.159C965.117 127.035 967.662 137.533 972.753 146.653C977.843 155.772 984.737 162.983 993.433 168.285C1002.34 173.375 1012.52 175.92 1023.98 175.92Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1226.67 0.00792152L1259.89 0.00770394C1277.12 -0.21036 1295.04 4.21733 1308.34 11.1955C1321.64 17.9555 1332 27.4414 1339.42 39.6531C1347.05 51.6468 1351.89 63.5261 1351.89 79.2269C1351.89 94.9277 1347.05 110.852 1339.42 123.064C1332 135.057 1321.64 144.543 1308.34 151.521C1295.04 158.281 1277.12 158.447 1259.89 158.447H1226.67V230H1165.33V0.00792152H1226.67ZM1259.58 102.228C1265.03 102.228 1269.83 101.149 1273.98 99.1864C1278.12 97.2237 1281.39 94.4978 1283.79 91.0086C1286.19 87.5194 1288 83.5892 1288 79.2277C1288 72.6855 1284.77 67.3476 1279.54 63.2042C1274.52 58.8427 1267.87 56.2277 1259.58 56.2277H1226.67V102.228H1259.58Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1427.05 215.366C1445.07 225.122 1465.75 230 1489.09 230C1512.21 230 1532.67 225.122 1550.49 215.366C1568.31 205.399 1582.2 191.826 1592.17 174.647C1602.35 157.257 1607.44 137.427 1607.44 115.159C1607.44 92.6787 1602.35 72.8494 1592.17 55.671C1581.99 38.2805 1567.99 24.7073 1550.17 14.9517C1532.36 4.98399 1511.78 0 1488.45 0C1465.33 0 1444.86 4.98399 1427.05 14.9517C1409.23 24.7073 1395.23 38.2805 1385.05 55.671C1374.87 72.8494 1369.78 92.6787 1369.78 115.159C1369.78 137.427 1374.87 157.257 1385.05 174.647C1395.23 191.826 1409.23 205.399 1427.05 215.366ZM1553.91 164.599L1515.52 192.886L1488.93 146.83L1462.1 193.285L1423.72 165.396L1458.97 125.335L1406.7 114.002L1421.35 68.5831L1470.24 90.2226L1464.87 36.7105H1512.36L1506.99 90.2226L1555.89 68.5831L1570.53 114.002L1518.76 125.226L1553.91 164.599Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1691.78 0.00792152L1725 0.00770394C1742.23 -0.21036 1760.15 4.21733 1773.45 11.1955C1786.75 17.9555 1797.11 27.4414 1804.53 39.6531C1812.16 51.6468 1817 63.5261 1817 79.2269C1817 94.9277 1812.16 110.852 1804.53 123.064C1797.11 135.057 1786.75 144.543 1773.45 151.521C1760.15 158.281 1742.23 158.447 1725 158.447H1691.78V230H1630.44V0.00792152H1691.78ZM1724.69 102.228C1730.15 102.228 1734.94 101.149 1739.09 99.1864C1743.23 97.2237 1746.5 94.4978 1748.9 91.0086C1751.3 87.5194 1753.11 83.5892 1753.11 79.2277C1753.11 72.6855 1749.88 67.3476 1744.65 63.2042C1739.63 58.8427 1732.98 56.2277 1724.69 56.2277H1691.78V102.228H1724.69Z"
        />
      </g>
    ),
  }),
};
